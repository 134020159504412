<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
<script>
import numeral from "numeral";
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    barWidth: {
      type: String,
      default: "50px",
    },
    height: {
      type: String,
      default: "300px",
    },
    pageType: {
      type: String,
      default: "",
    },
    label: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    fetchChart: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fetchChart() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
      setTimeout(() => {
        this.initChart();
      }, 400);
    },
  },
  data() {
    return {
      chart: null,
      checkChart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        legend: {
          selectedMode: false,
        },
        tooltip: {
          // trigger: "axis",
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          formatter: (params) => {
            // console.log(params);
            var output =
              params.seriesName + "<br/>" + params.marker + params.name;
            let values = Object.values(params.value);
            let setFindIndex = Object.keys(params.value).findIndex(
              (key) => key === params.seriesName
            );
            output +=
              "<span class='pd-l-6'>" + values[setFindIndex] + "  ราย </span>";
            return output;
          },
        },
        textStyle: {
          fontFamily: "DBHeavent",
          fontSize: 18,
          color: "#000",
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        dataset: {
          dimensions: ["product", "อนุมัติ", "อนุมัติมีเงื่อนไข", "ไม่อนุมัติ"],
          source: this.data,
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 1)",
            },
          },
          data: this.label.name,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 18,
          },
          show: this.showName,
        },
        yAxis: {
          axisLabel: {
            fontSize: 18,
            formatter: (params) => {
              let output = "";
              let findDot = params.toString().includes(".");
              if (!findDot) {
                output = params;
              }
              return output;
            },
          },
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              color: "#38D85B",
            },
            barGap: "0%",
            barWidth: this.barWidth,
          },
          {
            type: "bar",
            itemStyle: {
              color: "#96FFAD",
            },
            barGap: "0%",
            barWidth: this.barWidth,
          },
          {
            type: "bar",
            itemStyle: {
              color: "#FF646A",
            },
            barGap: "0%",
            barWidth: this.barWidth,
          },
        ],
      });
    },
  },
};
</script>
